import React from "react"
import { Script } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script
        src="https://cdn.dexem.net/dni_scripts/ad27dd9e-c69f-4dac-a115-41d1d4da1ce8/dni.js"
        strategy="post-hydrate"
        key={"dniScript"}
        // type="text/partytown"
        defer
      />

      {/* <Script
         // a utiliser en dev local pour tester le js sans avoir a rebuild, ne pas pousser!
         src="https://paritel-www-dev-cms.azurewebsites.net/yzy-content-builder/snippets.js"
         strategy="post-hydrate"
         key={"snippets"}
         async
       /> */}
      <Script
        src="/snippets.js"
        strategy="post-hydrate"
        key="snippets"
        async
      />

      <Script
        id="gg"
        key={"gg"}
        strategy="post-hydrate"
        type="text/partytown"
        defer
      >{`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('consent', 'default', {
            analytics_storage: window.localStorage.getItem("rgpd-acceptance_google-analitics_consent") === 'true' ? 'granted' : 'denied',
            ad_storage: window.localStorage.getItem("rgpd-acceptance_videos_consent") === 'true' ? 'granted' : 'denied',
            video_storage: window.localStorage.getItem("rgpd-acceptance_ads_consent") === 'true' ? 'granted' : 'denied',
          });
      `}</Script>
    </>
  )
}
