import { Swiper } from "swiper"
import "swiper/css"
import "video.js/dist/video-js.css"
import "videojs-youtube"

// require("./src/styles/site.scss")

export { wrapPageElement } from "./gatsby-shared"

function handleSwiperWrapper(swiperContainer) {
  const swiperSlides = swiperContainer.querySelectorAll(".swiper-slide")
  const btnPagination = swiperContainer.querySelector(
    ".btn-pagination-container"
  )

  let swiperWrapper = swiperContainer.querySelector(".swiper-wrapper")
  if (!swiperWrapper) {
    swiperWrapper = document.createElement("div")
    swiperWrapper.classList.add("swiper-wrapper")
    swiperWrapper.classList.add("swiper-wrapper-container")
    for (var i = 0; i < swiperSlides.length; i++) {
      swiperWrapper.appendChild(swiperSlides[i])
    }
    swiperContainer.insertBefore(swiperWrapper, btnPagination)
  }
}

function updateVideoSource(videoSource, videoElement) {
  if (videoSource) {
    if (videoSource.includes("youtube.com")) {
      videoElement.querySelector("source").src =
        videoSource + "&modestbranding=1&rel=0"
    } else {
      videoElement.querySelector("source").src = videoSource
    }
    import("video.js").then((videojs) => {
      const player = videojs.default(videoElement)
      player.src(videoElement.querySelector("source").src)
      player.load()
    })
  }
}



export const onRouteUpdate = async ({ location, prevLocation }) => {
  if (window.dexem_dni_ad27dd9ec69f4daca11541d1d4da1ce8) {
    window.dexem_dni_ad27dd9ec69f4daca11541d1d4da1ce8.init()
  }
  
  const videoTags = document.querySelectorAll("video[data-video-config]")
  if (videoTags) {
    for (const videoTag of videoTags) {
      const dynamicVideoSource = videoTag.querySelector("source")?.src
      const techOrder =
        dynamicVideoSource.includes("youtube.com") ||
        dynamicVideoSource.includes("youtu.be")
          ? ["youtube"]
          : ["html5"]

      const mergedVideoConfig = {
        techOrder,
        sources: [
          {
            type:
              dynamicVideoSource.includes("youtube.com") ||
              dynamicVideoSource.includes("youtu.be")
                ? "video/youtube"
                : "video/mp4",
            src: dynamicVideoSource,
          },
        ],
        ...window[videoTag.dataset.videoConfig],
      }
      import("video.js").then((videojs) => {
        const videoInstance = videojs.default(videoTag, mergedVideoConfig)
        window[videoTag.dataset.videoVarName] = videoInstance
        updateVideoSource(dynamicVideoSource, videoTag)
        if (
          videoTag.dataset.videoAfterInit &&
          window[videoTag.dataset.videoAfterInit] &&
          typeof window[videoTag.dataset.videoAfterInit] === "function"
        ) {
          window[videoTag.dataset.videoAfterInit](videoInstance)
        }
      })
    }
  }

  const swipers = document.querySelectorAll(
    ".contentBuilderSection .swiper, .hero .swiper"
  )
  if (swipers) {
    while (!window.snippetJsIsLoaded) {
      await new Promise((r) => setTimeout(r, 100));
      console.log("Wait snippet js is not loaded");
    }
    for (const swiper of swipers) {
      handleSwiperWrapper(swiper)
      const config = window[swiper.dataset.swiperConfig]
      console.log(
        "init swiper : " + swiper.dataset.swiperVarName + " config " + config
      )
      if (swiper.dataset.swiperVarName) {
        window[swiper.dataset.swiperVarName] = new Swiper(swiper, config)
      } else {
        new Swiper(swiper, config)
      }
      if (
        swiper.dataset.swiperAfterInit &&
        window[swiper.dataset.swiperAfterInit] &&
        typeof window[swiper.dataset.swiperAfterInit] === "function"
      ) {
        window[swiper.dataset.swiperAfterInit]()
      }
    }
  }


}
